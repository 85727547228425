import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

/**
 * Component to display the images
 *
 * @param {Object} props
 */
const Image = ({ src,
  srcSet,
  alt = '...',
  lazy = true,
  lazyProps = { width: 'auto', height: 'auto' },
  className,
  ...rest }) => {
  // const { src, srcSet, alt, lazy, lazyProps, className, ...rest } = props;

  if (lazy) {
    return (
      <LazyLoadImage
        className={clsx('image', className)}
        alt={alt}
        src={src}
        srcSet={srcSet}
        effect="opacity"
        style={{ display: 'block', width: '100%', height: '100%' }}
        {...lazyProps}
        {...rest}
      />
    );
  }

  return (
    <Box
      component="img"
      className={clsx('image', className)}
      alt={alt}
      src={src}
      srcSet={srcSet}
      sx={{ display: 'block', width: '100%', height: '100%' }}
      {...rest}
    />
  );
};

// Image.defaultProps = {
//   alt: '...',
//   lazy: true,
//   lazyProps: {
//     width: 'auto',
//     height: 'auto',
//   },
// };

Image.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Source of the image
   */
  src: PropTypes.string.isRequired,
  /**
   * Source set for the responsive images
   */
  srcSet: PropTypes.string,
  /**
   * Image title
   */
  alt: PropTypes.string,
  /**
   * Lazy loading properties
   */
  lazyProps: PropTypes.object,
  /**
   * Should lazy load the image
   */
  lazy: PropTypes.bool,
};

export default Image;
