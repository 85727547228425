import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase',
  },
}));

const SolutionEcommerce = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={<span className={classes.textWhite}>eCommerce B2C & B2B</span>}
        titleVariant="h1"
        subtitle={<span className={classes.textWhite}>Online presence is a must for better customer reach. Let's get your Store Online</span>}

        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

/*   ctaGroup={[
    <Button variant="outlined" color="secondary" size="large">
      See portfolio
    </Button>,
  ]}
  */

SolutionEcommerce.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default SolutionEcommerce;
