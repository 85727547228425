import React from 'react';
import { makeStyles } from '@mui/styles';
import { Policy } from './components';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
    pagePaddingTop: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(5),
        },
    },
    // sectionAlternate: {
    //   background: 'transparent',
    //   backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 40%, ${theme.palette.primary.dark} 0%)`,
    // },
}));

const Policies = ({ policies }) => {
    const classes = useStyles();

    return (
        <div>
            <Section className={classes.pagePaddingTop}>
                <Policy />
            </Section>
        </div>
    );
};

export default Policies;