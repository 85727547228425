/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Divider,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Root = styled(List)(({ theme }) => ({
  // Styles for the root container
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const NavLink = styled(Typography)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 'auto',
}));

const CloseIconStyled = styled(IconButton)(({ theme }) => ({
  justifyContent: 'flex-end',
  cursor: 'pointer',
}));

const Menu = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const MenuItem = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(8),
  '&:last-child': {
    marginRight: 0,
  },
}));

const MenuGroupItem = styled(ListItem)(({ theme }) => ({
  paddingTop: 0,
}));

const MenuGroupTitle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  width: '100%',
}));

const SidebarNav = props => {
  const { pages, onClose, className, ...rest } = props;

  const landings = pages.landings;
  const about = pages.about;

  const MenuGroup = ({ item }) => (
    <List disablePadding>
      <ListItemStyled disableGutters>
        <MenuGroupTitle variant="body2" color="primary">
          {item.groupTitle}
        </MenuGroupTitle>
      </ListItemStyled>
      {item.pages.map((page, i) => (
        <MenuGroupItem disableGutters key={i}>
          <NavLink
            variant="body2"
            component="a"
            href={page.href}
            color="textPrimary"
            onClick={() => onClose()}
          >
            {page.title}
          </NavLink>
        </MenuGroupItem>
      ))}
    </List>
  );

  const LandingPages = () => {
    const { services } = landings.children;
    return (
      <Menu>
        <MenuItem>
          <MenuGroup item={services} />
        </MenuItem>
      </Menu>
    );
  };

  const AboutPages = () => {
    const { company } = about.children;
    return (
      <Menu>
        <MenuItem>
          <MenuGroup item={company} />
        </MenuItem>
      </Menu>
    );
  };

  return (
    <Root {...rest} className={className}>
      <ListItemStyled>
        <CloseIconStyled onClick={() => onClose()}>
          <CloseIcon fontSize="small" />
        </CloseIconStyled>
      </ListItemStyled>
      {/* <ListItemStyled>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Landings
        </Typography>
        <LandingPages />
      </ListItemStyled> */}
      <ListItemStyled>
        <DividerStyled />
      </ListItemStyled>
      <ListItemStyled>
        {/* <Typography variant="h6" color="textPrimary" gutterBottom>
          About
        </Typography> */}
        <AboutPages />
      </ListItemStyled>
      <ListItemStyled>
        <DividerStyled />
      </ListItemStyled>
    </Root>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default SidebarNav;
