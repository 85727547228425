import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery, Divider } from '@mui/material';
import { colors } from '@mui/material';
import { IconAlternate } from 'components/molecules';
import { Section, SectionAlternate, Parallax } from 'components/organisms';
import {
  Welcome,
  WeStandFor,
  SolutionStartUp,
  SolutionEcommerce,
  SolutionProductService,
  SolutionSkills,
  CoreValues,
  CoreValuesMobile,
  Features,
  Reviews,
  Contact,
} from './components';

import { services, reviews, coreValues } from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  fullHeight: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disablePaddingTop: {
    paddingTop: 0,
  },
  scrollIcon: {
    background: 'transparent !important',
    border: `2px solid ${colors.amber[500]}`,
    cursor: 'pointer',
  },
  scrollTopIcon: {
    margin: '0 auto',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(12),
    },
  },
  coreValuesSection: {
    backgroundColor: '#0000000a',
  },
  madcapSection: {
    backgroundColor: '#0000008a',
  },
  adidasSection: {
    backgroundColor: '#003c0580',
  },
  larqSection: {
    backgroundColor: '#2d402f80',
  },
  nikeSection: {
    backgroundColor: '#5f3f3f80',
  },
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.dark} 0%)`,
  },
}));

const Home = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const scrollTo = (id) => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };

  return (
    <div className={classes.root}>
      <Parallax backgroundImage={'/assets/images/home/welcome.jpg'} id="home">
        <div className={classes.fullHeight}>
          <Section className={classes.disablePaddingTop}>
            <Welcome />
          </Section>
          <IconAlternate
            shape="circle"
            fontIconClass="fas fa-chevron-down"
            color={colors.amber}
            size="small"
            className={classes.scrollIcon}
            onClick={() => scrollTo('home-westandfor')}
          />
        </div>
      </Parallax>
      <Parallax
        backgroundImage={'/assets/images/home/we_stand_for_bg.png'}
        id="home-westandfor"
      >
        <div className={clsx(classes.fullHeight, classes.madcapSection)}>
          <Section>
            <WeStandFor />
          </Section>
          <IconAlternate
            shape="circle"
            fontIconClass="fas fa-chevron-down"
            color={colors.amber}
            size="small"
            className={classes.scrollIcon}
            onClick={() => scrollTo('home-features')}
            data-aos="fade-up"
          />
        </div>
      </Parallax>
      <div className={classes.fullHeight}>
        <Section id="home-features">
          <Features data={services} />
        </Section>
        <IconAlternate
          shape="circle"
          fontIconClass="fas fa-chevron-down"
          color={colors.amber}
          size="small"
          className={classes.scrollIcon}
          onClick={() => scrollTo('home-startup')}
        />
      </div>

      <Parallax
        backgroundImage={'/assets/images/home/startup.jpg'}
        id="home-startup"
      >
        <div className={clsx(classes.fullHeight, classes.madcapSection)}>
          <Section>
            <SolutionStartUp />
          </Section>
          <IconAlternate
            shape="circle"
            fontIconClass="fas fa-chevron-down"
            color={colors.amber}
            size="small"
            className={classes.scrollIcon}
            onClick={() => scrollTo('home-ecommerce')}
            data-aos="fade-up"
          />
        </div>
      </Parallax>
      <Parallax
        backgroundImage={'/assets/images/home/ecommerce.jpg'}
        id="home-ecommerce"
      >
        <div className={clsx(classes.fullHeight, classes.madcapSection)}>
          <Section>
            <SolutionEcommerce />
          </Section>
          <IconAlternate
            shape="circle"
            fontIconClass="fas fa-chevron-down"
            color={colors.amber}
            size="small"
            className={classes.scrollIcon}
            onClick={() => scrollTo('home-product')}
            data-aos="fade-up"
          />
        </div>
      </Parallax>
      <Parallax
        backgroundImage={'/assets/images/home/product_service.jpg'}
        id="home-product"
      >
        <div className={clsx(classes.fullHeight, classes.madcapSection)}>
          <Section>
            <SolutionProductService />
          </Section>
          <IconAlternate
            shape="circle"
            fontIconClass="fas fa-chevron-down"
            color={colors.amber}
            size="small"
            className={classes.scrollIcon}
            onClick={() => scrollTo('home-skills')}
            data-aos="fade-up"
          />
        </div>
      </Parallax>
      <Parallax
        backgroundImage={'/assets/images/home/solution_skills.png'}
        id="home-skills"
      >
        <div className={clsx(classes.fullHeight, classes.madcapSection)}>
          <Section>
            <SolutionSkills />
          </Section>
          <IconAlternate
            shape="circle"
            fontIconClass="fas fa-chevron-down"
            color={colors.amber}
            size="small"
            className={classes.scrollIcon}
            onClick={() => scrollTo('home-core_values')}
            data-aos="fade-up"
          />
        </div>
      </Parallax>

      {isMd && (
        <Parallax
          backgroundImage="/assets/images/home/core_values.png"
          id="home-core_values"
        >
          <div className={clsx(classes.fullHeight, classes.coreValuesSection)}>
            <Section>
              <CoreValues />
            </Section>
            <IconAlternate
              shape="circle"
              fontIconClass="fas fa-chevron-down"
              color={colors.amber}
              size="small"
              className={classes.scrollIcon}
              onClick={() => scrollTo('home-contact')}
              data-aos="fade-up"
            />
          </div>
        </Parallax>
      )}

      {!isMd && (
        <Parallax
          backgroundImage="/assets/images/home/we_stand_for_bg.png"
          id="home-core_values"
        >
          <div className={clsx(classes.fullHeight, classes.coreValuesSection)}>
            <Section>
              <CoreValuesMobile data={coreValues} />
            </Section>
            <IconAlternate
              shape="circle"
              fontIconClass="fas fa-chevron-down"
              color={colors.amber}
              size="small"
              className={classes.scrollIcon}
              onClick={() => scrollTo('home-contact')}
              data-aos="fade-up"
            />
          </div>
        </Parallax>
      )}
      <Divider />
      <SectionAlternate id="home-contact" className={classes.sectionAlternate}>
        <Contact />
        <IconAlternate
          shape="circle"
          fontIconClass="fas fa-chevron-up"
          color={colors.amber}
          size="small"
          className={clsx(classes.scrollIcon, classes.scrollTopIcon)}
          onClick={() => scrollTo('home-features')}
          data-aos="fade-up"
        />
      </SectionAlternate>
      <Divider />
    </div>
  );
};

export default Home;
