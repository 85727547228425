/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Typography, Grid2 as Grid, Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LearnMoreLink } from 'components/atoms';

const StyledAccordion = styled(MuiAccordion)(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
  cursor: 'pointer',
}));

const StyledAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  '&.Mui-expanded': {
    color: theme.palette.primary.dark,
    transform: 'rotate(180deg)',
  },
}));

/**
 * Component to display accordion view
 *
 * @param {*} props
 */
const Accordion = (props) => {
  const {
    items,
    className,
    titleProps,
    subtitleProps,
    textProps,
    linkProps,
    ...rest
  } = props;

  return (
    <div {...rest} className={className}>
      {items.map(item => (
        <StyledAccordion
          key={item.id}
        >
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${item.id}-content`}
            id={item.id}
          >
            <Grid
              container
              spacing={0}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  {...titleProps}
                >
                  {item.title}
                </Typography>
              </Grid>
              {item.subtitle && (
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    {...subtitleProps}
                  >
                    {item.subtitle}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  {...textProps}
                >
                  {item.text}
                </Typography>
              </Grid>
              {item.link && (
                <Grid item xs={12}>
                  <LearnMoreLink
                    title={item.link}
                    variant="body1"
                    {...linkProps}
                  />
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
};

Accordion.defaultProps = {
  titleProps: {},
  subtitleProps: {},
  textProps: {},
  linkProps: {},
};

Accordion.propTypes = {
  /**
   * Classname from the parent component
   */
  className: PropTypes.string,
  /**
   * Items to show inside the accordion
   */
  items: PropTypes.array.isRequired,
  /**
   * Additional properties to pass to the title Typography component
   */
  titleProps: PropTypes.object,
  /**
   * Additional properties to pass to the subtitle Typography component
   */
  subtitleProps: PropTypes.object,
  /**
   * Additional properties to pass to the text Typography component
   */
  textProps: PropTypes.object,
  /**
   * Additional properties to pass to the link component
   */
  linkProps: PropTypes.object,
};

export default Accordion;
