import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardMedia, CardContent, Grid2 as Grid, Button, Typography, colors } from '@mui/material';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  textBlue: {
    color: 'navyblue',
//    textTransform: 'uppercase',
  },
}));

const UseCases = props => {
  const { data, className, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <SectionHeader 
        title="Lokey is Platform for Everyone" fadeUp
        subtitle="Empowering Vendors to show their digital presence and serve in real world in person"
        disableGutter
        data-aos="fade-up"
      />


      <Grid container spacing={4} mt={4}>
        {data.map((item, index) => (
          <Grid
            key={index}
            alignItems="center"
            direction="column"
            size={{xs:12, md:3}}            
            data-aos="fade-up"
            data-aos-delay={index * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Box
              display={'block'}
              width={1}
              height={1}
              sx={{
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                display={'flex'}
                flexDirection={'column'}
                sx={{ backgroundImage: 'none' }}
              >
                <CardMedia
                  title={item.category}
                  sx={{
                    position: 'relative',
                    height: { xs: 240, sm: 340, md: 280 },
                    overflow: 'hidden',                    
                    '& .lazy-load-image-background.lazy-load-image-loaded': {
                      display: 'flex !important',
                    },
                  }}
                >
                      <Box
                        key={item.image}
                        component={Image}
                        effect="blur"                        
                        src={item.image}
                        sx={{
                          width: '100%', // Ensure the image takes full width of the CardMedia
                          height: '100%',
                          // height: { xs: 240, sm: 340, md: 280 }, // Match the height of the CardMedia
                         objectFit: 'cover', // Ensure the image covers the container proportionally
                        //  imageRendering: 'auto',
                        }}
                      />
                  
                  <Box
                    component={'svg'}
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1920 100.1"
                    sx={{
                      width: '100%',
                      bottom: 0,
                      position: 'absolute',
                    }}
                  >
                    <path
                      fill={theme.palette.background.default}
                      d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                    ></path>
                  </Box>
                </CardMedia>
                <CardContent>
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                    align={'left'}
                  >
                    {item.category}
                  </Typography>
                  <Typography
                    align={'left'}
                    variant={'subtitle2'}
                    color="text.secondary"
                  >
                    {item.description}
                  </Typography>                  
                </CardContent>
                <Box flexGrow={1} />                
              </Box>
            </Box>           
          </Grid>
        ))}        
      </Grid>
    </div>
  );
};

UseCases.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default UseCases;
