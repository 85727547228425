import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Section } from 'components/organisms';

// Styled components
const Root = styled('div')(({ backgroundImage, backgroundSize, backgroundPosition }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: backgroundSize || 'cover',
  backgroundPosition: backgroundPosition || 'center',
  backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
}));

/**
 * Component to display the simple background hero
 *
 * @param {Object} props
 */
const HeroSimpleBackground = props => {
  const {
    children,
    backgroundSize,
    backgroundImage,
    backgroundPosition,
    className,
    ...rest
  } = props;

  return (
    <Root
      className={className}
      backgroundImage={backgroundImage}
      backgroundSize={backgroundSize}
      backgroundPosition={backgroundPosition}
      {...rest}
    >
      <Section className="hero-simple-background__section">{children}</Section>
    </Root>
  );
};

HeroSimpleBackground.defaultProps = {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

HeroSimpleBackground.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The main content
   */
  children: PropTypes.node.isRequired,
  /**
   * The background image of the hero
   */
  backgroundImage: PropTypes.string.isRequired,
  /**
   * The background size of the hero
   */
  backgroundSize: PropTypes.string,
  /**
   * The background position of the hero
   */
  backgroundPosition: PropTypes.string,
};

export default HeroSimpleBackground;
