import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, Typography, colors } from '@mui/material';
import { styled } from '@mui/system';
import { IconAlternate } from 'components/molecules';
import { CardBase } from 'components/organisms';

// Define styled components
const Root = styled(CardBase)(({ theme }) => ({
  height: '100%',
  width: '100%',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
}));

/**
 * Component to display the promo card
 *
 * @param {Object} props
 */
const CardPromo = (props) => {
  const {
    titleColor,
    fontIconClass,
    color,
    title,
    subtitle,
    description,
    align,
    className,
    iconAlternateProps,
    titleProps,
    subtitleProps,
    descriptionProps,
    ...rest
  } = props;

  let justifyGrid = 'flex-start';
  if (align === 'center') {
    justifyGrid = 'center';
  } else if (align === 'right') {
    justifyGrid = 'flex-end';
  }

  return (
    <Root className={clsx('card__promo', className)} {...rest}>
      <Grid container spacing={2} className="card-promo__wrapper">
        <Grid
          item
          container
          justifyContent={justifyGrid}
          xs={12}
          className="card-promo__icon-wrapper"
        >
          <IconAlternate
            fontIconClass={fontIconClass}
            color={color}
            size="medium"
            className="card-promo__icon"
            {...iconAlternateProps}
          />
        </Grid>
        <Grid item xs={12} className="card-promo__title-wrapper">
          <Title
            variant="h4"
            align={align}
            className="card-promo__title"
            color={titleColor || 'textPrimary'}
            {...titleProps}
          >
            {title}
          </Title>
        </Grid>
        {subtitle && (
          <Grid item xs={12} className="card-promo__subtitle-wrapper">
            <Typography
              variant="h6"
              align={align}
              className="card-promo__subtitle"
              sx={{ fontWeight: 700 }}
              {...subtitleProps}
            >
              {subtitle}
            </Typography>
          </Grid>
        )}
        {description && (
          <Grid item xs={12} className="card-promo__description-wrapper">
            <Typography
              variant="subtitle1"
              color="textSecondary"
              align={align}
              className="card-promo__description"
              {...descriptionProps}
            >
              {description}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Root>
  );
};

CardPromo.defaultProps = {
  align: 'left',
  iconAlternateProps: {},
  titleProps: {},
  subtitleProps: {},
  descriptionProps: {},
};

CardPromo.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Promo title to show inside the card
   */
  title: PropTypes.string.isRequired,
  /**
   * Promo subtitle to show inside the card
   */
  subtitle: PropTypes.string,
  /**
   * Promo description to show inside the card
   */
  description: PropTypes.string,
  /**
   * Promo font icon class name to show inside the card
   */
  fontIconClass: PropTypes.string,
  /**
   * Promo icon color to show inside the card
   */
  color: PropTypes.oneOf(Object.values(colors)).isRequired,
  /**
   * The content alignment
   */
  align: PropTypes.oneOf(['left', 'right', 'center']),
  /**
   * Title color
   */
  titleColor: PropTypes.string,
  /**
   * Additional props to pass to the IconAlternate component
   */
  iconAlternateProps: PropTypes.object,
  /**
   * Additional props to pass to the title Typography component
   */
  titleProps: PropTypes.object,
  /**
   * Additional props to pass to the subtitle Typography component
   */
  subtitleProps: PropTypes.object,
  /**
   * Additional props to pass to the description Typography component
   */
  descriptionProps: PropTypes.object,
};

export default CardPromo;
