import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { Icon } from 'components/atoms';
import { colors } from '@mui/material';

const useBackgroundStyles = (color) => styled(Avatar)(({ theme }) => ({
  backgroundColor: color[50],
}));

const sizes = {
  extraSmall: {
    width: 20,
    height: 20,
  },
  small: {
    width: 50,
    height: 50,
  },
  medium: {
    width: 70,
    height: 70,
  },
  large: {
    width: 90,
    height: 90,
  },
};

const shapes = {
  circle: {
    borderRadius: '50%',
  },
  square: {
    borderRadius: 8, // Equivalent to theme.spacing(2) for a square shape
  },
};

/**
 * Component to display the alternate icon
 *
 * @param {Object} props
 */
const IconAlternate = (props) => {
  const {
    iconProps,
    fontIconClass,
    size = 'medium',
    color = colors.grey,
    shape = 'square',
    className,
    ...rest
  } = props;

  const BackgroundAvatar = useBackgroundStyles(color);

  return (
    <BackgroundAvatar
      sx={{
        ...sizes[size],
        ...shapes[shape],
        ...{ backgroundColor: color[50] },
      }}
      className={className}
      {...rest}
    >
      <Icon
        size={size}
        fontIconClass={fontIconClass}
        fontIconColor={color[500]}
        className="icon-alternate__icon"
        {...iconProps}
      />
    </BackgroundAvatar>
  );
};

IconAlternate.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The classes of the font icon
   */
  fontIconClass: PropTypes.string.isRequired,
  /**
   * Sizes of the icon
   */
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large']),
  /**
   * Color of the icon
   */
  color: PropTypes.oneOf([
    colors.red,
    colors.pink,
    colors.purple,
    colors.deepPurple,
    colors.indigo,
    colors.blue,
    colors.lightBlue,
    colors.cyan,
    colors.teal,
    colors.green,
    colors.lightGreen,
    colors.lime,
    colors.yellow,
    colors.amber,
    colors.orange,
    colors.deepOrange,
    colors.brown,
    colors.grey,
    colors.blueGrey,
  ]),
  /**
   * The shape of the alternate icon
   */
  shape: PropTypes.oneOf(['circle', 'square']),
  /**
   * Additional properties to pass to the Icon component
   */
  iconProps: PropTypes.object,
};

export default IconAlternate;
