
import React from 'react';

import Carousel from './Carousel';


const slides = [
    {
        title: 'Lokey, your local social e-commerce app',
        subtitle: 'Platform connecting people, vendors and businesses directly to customer for location context',
        background: '/assets/home/carousel/carousel_1.jpg',
    },
    {
        title: 'Organize Unorganized Market On App',
        subtitle: 'Get all offers on food, travel or any service in one place in context of your location and time',
        background: '/assets/home/carousel/carousel_2.jpg',
    },
    {
        title: 'Create Grocery Offers Reach out Digitally',
        subtitle: 'Announce your offer for the day at given location, Customers will find you on platform and buy physically',
        background: '/assets/home/carousel/carousel_3.jpg',
    },
    {
        title: 'Create Skill Offers Reach out Digitally',
        subtitle: 'Publish your availability to offer service for Customers to search, connect & avail your service',
        background: '/assets/home/carousel/carousel_4.jpg',
    },
];


const HeroSection = (props) => {

    return (
        <Carousel slides={slides} />
    );




}

export default HeroSection;