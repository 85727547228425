import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import clsx from 'clsx';

// Styled components
const Root = styled('section')(({ theme }) => ({
  background: theme.palette.alternate.main,
}));

const Inner = styled('div')(({ theme, innerNarrowed }) => ({
  maxWidth: theme.layout.contentWidth,
  width: '100%',
  margin: '0 auto',
  padding: theme.spacing(6, 2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8, 8),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(12, 8),
  },
  ...(innerNarrowed && { maxWidth: 800 }),
}));

/**
 * Component to display the alternative sections
 *
 * @param {Object} props
 */
const SectionAlternate = props => {
  const { children, innerNarrowed, className, ...rest } = props;

  return (
    <Root className={clsx('section-alternate', className)} {...rest}>
      <Inner innerNarrowed={innerNarrowed}>
        {children}
      </Inner>
    </Root>
  );
};

SectionAlternate.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Children to placed inside the section
   */
  children: PropTypes.node,
  /**
   * Should show narrow sections
   */
  innerNarrowed: PropTypes.bool,
};

export default SectionAlternate;
