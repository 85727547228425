import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Grid, useTheme, Divider } from '@mui/material';
import { Image } from 'components/atoms';
import clsx from 'clsx';

// Styled components
const Root = styled(Grid)(({ theme }) => ({
  position: 'relative',
}));

const ImageWrapper = styled(Grid)(({ theme }) => ({
  position: 'relative',
  maxHeight: 400,
}));

const ImageStyled = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const Cover = styled('div')(({ backgroundColor }) => ({
  background: backgroundColor || '#303F9F', // Default to indigo[900]
  opacity: 0.6,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
}));

const Content = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  padding: theme.spacing(2),
  top: '50%',
  transform: 'translateY(-50%)',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    transform: 'none',
    padding: theme.spacing(0, 4),
  },
}));

/**
 * Component to display the side image hero
 *
 * @param {Object} props
 */
const HeroSideImage = (props) => {
  const {
    imageSrc,
    imageSrcSet,
    children,
    backgroundColor,
    reverse,
    className,
    ...rest
  } = props;

  const theme = useTheme();

  return (
    <Root
      container
      className={clsx('hero-side-image', className)}
      direction={reverse ? 'row-reverse' : 'row'}
      {...rest}
    >
      <ImageWrapper
        item
        xs={12}
        md={6}
        className="hero-side-image__image-wrapper"
      >
        <ImageStyled
          src={imageSrc}
          srcSet={imageSrcSet || ''}
          alt="Side Image"
          className="hero-side-image__image"
        />
        <Cover className="hero-side-image__cover" backgroundColor={backgroundColor} />
      </ImageWrapper>
      <Content
        item
        xs={12}
        md={6}
        className="hero-side-image__content"
      >
        {children}
      </Content>
    </Root>
  );
};

HeroSideImage.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Children to be placed inside the hero
   */
  children: PropTypes.node,
  /**
   * Background color of the hero
   */
  backgroundColor: PropTypes.string,
  /**
   * Side image
   */
  imageSrc: PropTypes.string.isRequired,
  /**
   * Side image srcset
   */
  imageSrcSet: PropTypes.string,
  /**
   * Should show in reverse order
   */
  reverse: PropTypes.bool,
};

export default HeroSideImage;
