import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import { indigo } from '@mui/material/colors';

/**
 * Component to display the dark mode toggler
 *
 * @param {Object} props
 */
const DarkModeToggler = ({ themeMode = 'light', onClick, className, ...rest }) => {
  const theme = useTheme();

  return (
    <Box
      component="span"
      className={clsx(className)}
      {...rest}
      onClick={onClick}
      sx={{
        position: 'relative',
        display: 'inline-block',
      }}
    >
      <Box
        sx={{
          width: { xs: theme.spacing(5), md: theme.spacing(6) },
          height: { xs: theme.spacing(2), md: theme.spacing(3) },
          borderRadius: theme.spacing(3),
          border: '3px solid',
          borderColor: theme.palette.divider,
          backgroundColor: 'transparent',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: `-${theme.spacing(0.5)}`,
          left: `-${theme.spacing(0.5)}`,
          width: { xs: theme.spacing(3), md: theme.spacing(4) },
          height: { xs: theme.spacing(3), md: theme.spacing(4) },
          borderRadius: '50%',
          backgroundColor: themeMode === 'dark' ? indigo[900] : theme.palette.text.primary,
          transition: 'transform .3s cubic-bezier(.4,.03,0,1)',
          transform: themeMode === 'dark' ? `translateX(${theme.spacing(3)})` : 'none',
          cursor: 'pointer',
        }}
      >
        <svg
          aria-hidden="true"
          width="14"
          height="13"
          viewBox="0 0 14 13"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            fill: theme.palette.secondary.main,
            marginTop: { xs: theme.spacing(0.5), md: theme.spacing(1) },
            marginLeft: { xs: theme.spacing(0.5), md: theme.spacing(1) },
          }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.52208 7.71754C7.5782 7.71754 10.0557 5.24006 10.0557 2.18394C10.0557 1.93498 10.0392 1.68986 10.0074 1.44961C9.95801 1.07727 10.3495 0.771159 10.6474 0.99992C12.1153 2.12716 13.0615 3.89999 13.0615 5.89383C13.0615 9.29958 10.3006 12.0605 6.89485 12.0605C3.95334 12.0605 1.49286 10.001 0.876728 7.24527C0.794841 6.87902 1.23668 6.65289 1.55321 6.85451C2.41106 7.40095 3.4296 7.71754 4.52208 7.71754Z"
          />
        </svg>
      </Box>
    </Box>
  );
};

DarkModeToggler.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The theme mode
   */
  themeMode: PropTypes.string,
  /**
   * Theme toggler function
   */
  onClick: PropTypes.func.isRequired,
};

export default DarkModeToggler;
