import { colors } from '@mui/material';


export const useCases = [
  {
    category: 'Puncture Shop',
    description:
      'Make Travellers aware that your are available to fix tyres if need be',
    image: '/assets/home/use-cases/puncture-shop.png',  
  },
  {
    category: 'Florist',
    description:
      'Let world know where they can find special flowers',
    image: '/assets/home/use-cases/flourist.jpg',  
  },
  {
    category: 'Special Bags',
    description:
      'Inform people on the Bag Varieties you are selling',
    image: '/assets/home/use-cases/bags-seller.jpg',  
  },
  {
    category: 'Fruits Vendor',
    description:
      'Offer the best Fruits of the season',
    image: '/assets/home/use-cases/fruits.jpg',  
  },
  {
    category: 'Handy Man',
    description:
      'Let people know you are available to provide your service',
    image: '/assets/home/use-cases/handyman.png',  
  },
  {
    category: 'Chaats Vendor',
    description:
      'Lead your customers to where the best chat is available',
    image: '/assets/home/use-cases/chaats.png',  
  },
  {
    category: 'Vegetable Vendor',
    description:
      'Announce you are in the proximity with fresh vegetables',
    image: '/assets/home/use-cases/vegetable-vendor.png',  
  },
  {
    category: 'Bars & Restaurants',
    description:
      'Publish your happy hours offers',
    image: '/assets/home/use-cases/happyhours.jpg',  
  },
];




export const partners = [
  {
    name: 'Airbnb',
    logo: 'https://assets.maccarianagency.com/the-front/logos/airbnb.svg',
  },
  {
    name: 'Coinbase',
    logo: 'https://assets.maccarianagency.com/the-front/logos/coinbase.svg',
  },
  {
    name: 'Dribbble',
    logo: 'https://assets.maccarianagency.com/the-front/logos/dribbble.svg',
  },
  {
    name: 'Instagram',
    logo: 'https://assets.maccarianagency.com/the-front/logos/instagram.svg',
  },
  {
    name: 'Netflix',
    logo: 'https://assets.maccarianagency.com/the-front/logos/netflix.svg',
  },
  {
    name: 'Pinterest',
    logo: 'https://assets.maccarianagency.com/the-front/logos/pinterest.svg',
  },
];

export const features = [
  {
    label: 'STRATEGY',
    title: 'Business Transformation',
    description:
      'We transform businesses into brands through enhanced shoppable experience, content and marketing solutions',
    illustration: '/assets/images/solution/transformation.jpg',
    list: [ "Business Consulting",
            "Business Process Analysis",
            "End 2 End Process Consulting",
            "Enhance Shoppable Experience",
            "Digital Content and Marketing"
          ]
  },
  {
    label: 'SOLUTION',
    title: 'Digital Enablement',
    description:
      'We build solutions to ensure your teams are digitally enabled to focus more on business and generate value for customers',
    illustration: '/assets/images/solution/enablement.jpg',
    list: [
      "Startups Tech Enablement",
      "Digital Benchmarking",
      "Automate operations",
      "TCO Analysis & Optimization",
      "Ecommerce Implementation",
      "Content & Marketing",
      "SEO",
      "Data Analytics",
      "Cloud Enablement",
      "Specialized Consultants",
    ]
  },
  {
    label: 'DELIVERY',
    title: 'Engineering Solutions',
    description:
      'We help organizations to implementing long lasting & scalable end to end solutions by leveraging platforms and microservices',
    illustration: '/assets/images/solution/delivery.jpg',
    list: [
      "Consulting Services",
      "eCommerce ( B2C, B2B, Marketplace ) Solutions",
      "Microservices and Headless Experience",
      "Content & Marketing Solutions",
      "Mobile Application Development",
      "Custom & Specialized Solutions ( Dashboards, Workflows)",
      "PWA Enablement",
      "Managed Services"
    ],
  },
];

export const browsers = [
  {
    name: 'Google Chrome',
    logo: 'https://assets.maccarianagency.com/the-front/illustrations/browsers/chrome.png',
    title: 'Google Chrome is a cross-platform web browser developed by Google.',
  },
  {
    name: 'Safari',
    logo: 'https://assets.maccarianagency.com/the-front/illustrations/browsers/safari.png',
    title:
      'Safari is a graphical web browser developed by Apple, based on the WebKit engine.',
  },
  {
    name: 'Internet Explorer',
    logo: 'https://assets.maccarianagency.com/the-front/illustrations/browsers/explorer.png',
    title:
      'Internet Explorer is a series of graphical web browsers developed by Microsoft.',
  },
  {
    name: 'Mozilla Firefox',
    logo: 'https://assets.maccarianagency.com/the-front/illustrations/browsers/firefox.png',
    title:
      'Mozilla Firefox, or simply Firefox, is a free and web browser developed by the Mozilla.',
  },
  {
    name: 'Microsoft Edge',
    logo: 'https://assets.maccarianagency.com/the-front/illustrations/browsers/edge.png',
    title:
      'Microsoft Edge is a web browser developed by Microsoft. It was first released for Windows 10.',
  },
];

export const advantages = [
  {
    title: 'Build Recurring Revenue',
    icon: 'fas fa-money-bill-wave',
    color: colors.indigo,
  },
  {
    title: 'Competitive Differentation',
    icon: 'fas fa-trophy',
    color: colors.orange,
  },
  {
    title: 'Keep Your Clients Loyal',
    icon: 'fas fa-heart',
    color: colors.red,
  },
  {
    title: 'Automated Sales Process',
    icon: 'fas fa-cog',
    color: colors.purple,
  },
  {
    title: '3rd-Party Integrations',
    icon: 'fas fa-plug',
    color: colors.blue,
  },
  {
    title: 'Meets Privacy Requirements',
    icon: 'fas fa-lock',
    color: colors.green,
  },
  {
    title: 'Marketing & Sales Collateral',
    icon: 'fas fa-hand-holding-heart',
    color: colors.pink,
  },
  {
    title: 'Integrates With Most Hardware',
    icon: 'fas fa-plus',
    color: colors.deepOrange,
  },
];

export const webinars = [
  {
    title: 'Manage Service Providers',
    description:
      'Add new recurring revenue streams to existing managed WiFi networks.',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
  },
  {
    title: 'Value Added Resellers',
    description:
      'Add Social WiFi Marketing to your software bundles and suite of services.',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
  },
  {
    title: 'Digital Services Agencies',
    description:
      'Help local business owners generate leads and build customer loyalty.',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
  },
  {
    title: 'Internet Service Providers',
    description:
      'Upsell Social WiFi as a Service to business Internet subscribers.',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
    },
    authorName: 'Kate Segelson',
  },
  {
    title: 'SaaS Aplications',
    description:
      'A lightweight Social WiFi API solution integrate deep into existing platforms.',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson@2x.jpg 2x',
    },
    authorName: 'Alex Johnson',
  },
  {
    title: 'Advertising Network',
    description:
      'Generate affiliate commissions by referring new reseller partners to us.',
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan@2x.jpg 2x',
    },
    authorName: 'Valeria Kogan',
  },
];

export const platforms = [
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/slack.svg',
    name: 'Slack',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/mailchimp.svg',
    name: 'Mailchimp',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/dropbox.svg',
    name: 'Dropbox',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/google-drive.svg',
    name: 'Google Drive',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/google-ad-manager.svg',
    name: 'Google Ad Manager',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/atlassian.svg',
    name: 'Atlassian',
  },
];
