import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import { useMediaQuery, Grid2 as Grid } from '@mui/material';
import { SectionHeader } from 'components/molecules';

const WhoWeAre = props => {
  const { className, ...rest } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item size={{xs:12, sm:6}} data-aos="fade-up">
          <SectionHeader
            title="Who can onboard?"
            subtitle="Platform is open to any vendor who wants to create offers and reach digitally to customers within their physical reach."
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
        </Grid>
        <Grid item size={{xs:12, sm:6}} data-aos="fade-up">
          <SectionHeader
            title="Where is it available?"
            subtitle="Platform is available across the world. Vendors will be driving Customers and Customers are expected to pull in more Vendors"
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

WhoWeAre.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhoWeAre;
