import React from 'react';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import Routes from './Routes';
import { SnackbarProvider } from 'notistack';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'leaflet/dist/leaflet.css';
import 'assets/css/index.css';
import 'swiper/css';
import 'aos/dist/aos.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import GoogleAnalytics from 'GoogleAnalytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBf57I5Fj2-HXRjQ-v9sZMUJlPGlDsKbDY",
  authDomain: "lokey-f1dc5.firebaseapp.com",
  projectId: "lokey-f1dc5",
  storageBucket: "lokey-f1dc5.appspot.com",
  messagingSenderId: "871637693603",
  appId: "1:871637693603:web:278b9cc552b0c0a53dbb16",
  measurementId: "G-CPPZT79KKV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  return (
    <SnackbarProvider maxSnack={1}>
      <BrowserRouter>
        <GoogleAnalytics />
        <Routes />
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;



