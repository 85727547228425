import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const Slide = ({ title, subtitle, backgroundImage, contentWidth = '50%' }) => {
    return (
        <Box>
            {/* Main Slide Container with Background Image */}
            <Box
                sx={{
                    height: {
                        xs: '60vh', // Only image in main container for mobile
                        md: '90vh', // Full height on desktop
                    },
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 5%',
                }}
            >
                {/* Title and Subtitle only show in this area on desktop */}
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' }, // Hide on mobile
                        width: contentWidth,
                        maxWidth: '40vw',
                        marginLeft: '50%',
                        position: 'absolute',
                        top: '20%',
                        flexDirection: 'column',
                        gap: 2,
                        textAlign: 'right',
                    }}
                >
                    {/* Title with Dark Blue Background */}
                    <Box
                        sx={{
                            backgroundColor: 'rgba(0, 0, 139, 0.7)',
                            padding: 2,
                            borderRadius: 1,
                        }}
                    >
                        <Typography
                            variant="h2"
                            gutterBottom
                            sx={{ fontWeight: 400, textAlign: 'left' }}
                        >
                            {title}
                        </Typography>
                    </Box>

                    {/* Subtitle with Light Blue Background */}
                    <Box
                        sx={{
                            backgroundColor: 'rgba(135, 206, 235, 0.7)',
                            padding: 2,
                            borderRadius: 1,
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 400, textAlign: 'left' }}
                        >
                            {subtitle}
                        </Typography>
                    </Box>
                </Box>

                {/* Button on Desktop */}
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' }, // Hide on mobile
                        position: 'absolute',
                        bottom: '20%',
                        left: '10%',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{
                            padding: '16px 32px',
                            fontSize: '1.5rem',
                            borderRadius: '40px',
                            boxShadow: 4,
                        }}
                    >
                        Let’s Get Started
                    </Button>
                </Box>
            </Box>

            {/* Mobile-only Text and Button Section */}
            <Box
                sx={{
                    display: { xs: 'flex', md: 'none' }, // Only show on mobile
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    padding: '2rem 5%',
                    // backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay background for mobile
                    backgroundColor: "black",
                    color: 'white',
                }}
            >
                {/* Title with Dark Blue Background */}
                <Box
                    sx={{
                        backgroundColor: 'rgba(0, 0, 139, 0.7)',
                        padding: 2,
                        borderRadius: 1,
                        width: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h4" sx={{ fontWeight: 400 }}>
                        {title}
                    </Typography>
                </Box>

                {/* Subtitle with Light Blue Background */}
                <Box
                    sx={{
                        backgroundColor: 'rgba(135, 206, 235, 0.7)',
                        padding: 2,
                        borderRadius: 1,
                        width: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
                        {subtitle}
                    </Typography>
                </Box>

                {/* Button on Mobile */}
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                        padding: '12px 24px',
                        fontSize: '1.2rem',
                        borderRadius: '40px',
                        boxShadow: 4,
                        width: '100%', // Full width on mobile
                    }}
                >
                    Let’s Get Started
                </Button>
            </Box>
        </Box>
    );
};

export default Slide;
