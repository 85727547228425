import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Box, Button, Grid, Typography } from '@mui/material';
import { SectionHeader } from 'components/molecules';
import { CardBase, DescriptionList } from 'components/organisms';
import { Image } from 'components/atoms';

const useStyles = makeStyles((theme) => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase',
  },
  textTitle: {
    color: 'yellow',
    textTransform: 'uppercase',
  },
  imageCoreValues: {
    width: '100%',
    height: '100%',
  },
}));

const CoreValues = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <Grid container justifyContent="center">
        <SectionHeader
          title={<span className={classes.textWhite}>Core Values</span>}
          titleVariant="h2"
          data-aos="fade-up"
        />
        <Grid item xs={12} sm={6} md={4}>
          <Box m={4}>
            <Image
              className={classes.imageCoreValues}
              src={'/assets/images/home/core_values_mobile.png'}
              alt={'Core Values'}
            />
          </Box>
        </Grid>
        <Grid container spacing={2}>
          {data.map((item, index) => (
            <Grid
              key={index}
              item
              container
              alignItems="center"
              direction="column"
              xs={12}
              sm={6}
              md={4}
              data-aos="fade-up"
            >
              <CardBase liftUp variant="outlined" withShadow>
                <DescriptionList
                  title={item.title}
                  subtitle={item.description}
                  align="left"
                />
              </CardBase>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <SectionHeader
        title={<span className={classes.textWhite}></span>}
        titleVariant="h1"
        subtitle={<span className={classes.textWhite}></span>}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

CoreValues.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default CoreValues;
