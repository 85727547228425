import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, CardContent } from '@mui/material';

const StyledCard = styled(Card, { shouldForwardProp: (prop) => !['withShadow', 'noShadow', 'noBorder', 'noBg', 'liftUp'].includes(prop) })(
  ({ theme, withShadow, noShadow, noBorder, noBg, liftUp }) => ({
    height: '100%',
    width: '100%',
    boxShadow: noShadow ? 'none' : withShadow ? `0 2px 10px 0 ${theme.palette.cardShadow}` : undefined,
    border: noBorder ? 'none' : undefined,
    background: noBg ? 'transparent' : undefined,
    transition: liftUp ? 'box-shadow .25s ease, transform .25s ease' : undefined,
    '&:hover': liftUp ? {
      boxShadow: '0 1.5rem 2.5rem rgba(22,28,45,.1), 0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important',
      transform: 'translate3d(0,-5px,0)',
    } : undefined,
  })
);

const StyledCardContent = styled(CardContent, { shouldForwardProp: (prop) => !['align'].includes(prop) })(
  ({ theme, align }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center',
    padding: theme.spacing(4, 2),
    '&:last-child': {
      padding: theme.spacing(4, 2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 3),
      '&:last-child': {
        padding: theme.spacing(6, 3),
      },
    },
  })
);

/**
 * Component to display the basic card
 *
 * @param {Object} props
 */
const CardBase = ({
  withShadow,
  noShadow,
  noBorder,
  noBg,
  liftUp,
  children,
  align = 'center',
  className,
  cardContentProps = {},
  ...rest
}) => {
  return (
    <StyledCard
      withShadow={withShadow}
      noShadow={noShadow}
      noBorder={noBorder}
      noBg={noBg}
      liftUp={liftUp}
      className={className}
      {...rest}
    >
      <StyledCardContent
        align={align}
        {...cardContentProps}
      >
        {children}
      </StyledCardContent>
    </StyledCard>
  );
};

CardBase.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The children content of the basic card
   */
  children: PropTypes.node.isRequired,
  /**
   * Whether to show custom shadow
   */
  withShadow: PropTypes.bool,
  /**
   * Whether to render the card without shadow
   */
  noShadow: PropTypes.bool,
  /**
   * Whether to hide the card borders
   */
  noBorder: PropTypes.bool,
  /**
   * Whether to show transparent background
   */
  noBg: PropTypes.bool,
  /**
   * Whether to lift up on hover
   */
  liftUp: PropTypes.bool,
  /**
   * The content alignment
   */
  align: PropTypes.oneOf(['left', 'right', 'center']),
  /**
   * Additional props to pass to the CardContent component
   */
  cardContentProps: PropTypes.object,
};

export default CardBase;
