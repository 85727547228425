import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Drawer, Box } from '@mui/material';
import { SidebarNav } from './components';

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  width: '100%',
  maxWidth: 325,
}));

const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(1),
}));

const NavStyled = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const Sidebar = props => {
  const { pages, open, variant, onClose, className, ...rest } = props;

  return (
    <DrawerStyled
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      PaperProps={{ className }}
    >
      <Root {...rest}>
        <NavStyled>
          <SidebarNav pages={pages} onClose={onClose} />
        </NavStyled>
      </Root>
    </DrawerStyled>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  pages: PropTypes.object.isRequired, // Added pages to propTypes
};

export default Sidebar;
