import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { Box, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    minHeight: 400,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  textWhite: {
    color: '#3a0ca3', // #0077b6', //#800f2f',
    align: 'right'

  },
  title: {
    fontWeight: 'bold',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '60%',
    transform: 'translate(-40%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <Box
      sx={{
        height: {
          xs: '50vh', // Only image in main container for mobile
          md: '60vh', // Full height on desktop
        },
        backgroundImage: `url("/assets/aboutus/aboutus.png")`,
        backgroundSize: 'cover',
        backgroundPosition: { xs:'20% center', lg: 'center',},
        position: 'relative',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 5%',
      }}
    >
      {/* Title and Subtitle only show in this area on desktop */}
      <Box
        sx={{
          display: { xs: 'flex', md: 'flex' }, // Hide on mobile
          width: '50%',
          maxWidth: '45vw',
          marginLeft: '50%',
          position: 'absolute',
          top: '20%',
          flexDirection: 'column',
          gap: 2,
          textAlign: 'right',
        }}
      >
        {/* Title with Dark Blue Background */}
        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 139, 0.7)',
            padding: 2,
            borderRadius: 1,
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{ fontWeight: 400, textAlign: 'left' }}
          >
            About Us
          </Typography>
        </Box>

        {/* Subtitle with Light Blue Background */}
        <Box
          sx={{
            backgroundColor: 'rgba(135, 206, 235, 0.7)',
            padding: 2,
            borderRadius: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 400, textAlign: 'left' }}
          >
            Focused group working towards bridging the gap between online and offline
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
