import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase',
  },
}));

const SolutionStartUp = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={<span className={classes.textWhite}>Startup Enabler</span>}
        titleVariant="h1"
        subtitle={<span className={classes.textWhite}>Not every startup is fortunate to have technical team to realise their dreams. Build it with us!</span>}

        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};
 /*     ctaGroup={[
      <Button variant="outlined" color="secondary" size="large">
        See portfolio
      </Button>
    ]} */ 

SolutionStartUp.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default SolutionStartUp;
