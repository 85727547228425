import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { Icon } from 'components/atoms';
import { grey } from '@mui/material/colors';
import clsx from 'clsx';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `solid 1px rgba(43, 41, 45, 0.2)`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  background: 'transparent',
  cursor: 'pointer',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
  '&:hover': {
    background: theme.palette.primary.main,
    boxShadow: '0 11px 55px 10px rgba(0, 0, 0, 0.07), 0 13px 18px -8px rgba(0, 0, 0, 0.15)',
    '& .card-job-minimal__title, & .card-job-minimal__location, & .card-job-minimal__arrow': {
      color: 'white !important',
    },
  },
}));

const CardJobMinimalBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginRight: theme.spacing(8),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

/**
 * Component to display the job card company
 *
 * @param {Object} props
 */
const CardJobMinimal = props => {
  const { title, subtitle, showArrow, titleProps, subtitleProps, className, ...rest } = props;

  return (
    <Root className={clsx('card-job-minimal', className)} {...rest}>
      <CardJobMinimalBody
        className="card-job-minimal__body"
        sx={!showArrow ? { marginRight: 0 } : {}}
      >
        <div className="card-job-minimal__title-container">
          <Title
            variant="subtitle1"
            className="card-job-minimal__title"
            {...titleProps}
          >
            {title}
          </Title>
        </div>
        <div className="card-job-minimal__location-container">
          <Typography
            variant="body2"
            color="textSecondary"
            className="card-job-minimal__location"
            {...subtitleProps}
          >
            {subtitle}
          </Typography>
        </div>
      </CardJobMinimalBody>
      {showArrow ? (
        <div className="card-job-minimal__arrow-container">
          <Icon
            fontIconClass="fas fa-angle-right"
            fontIconColor={grey[700]}
            className="card-job-minimal__arrow"
          />
        </div>
      ) : null}
    </Root>
  );
};

CardJobMinimal.defaultProps = {
  showArrow: false,
  titleProps: {},
  subtitleProps: {},
};

CardJobMinimal.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Job title of the card
   */
  title: PropTypes.string.isRequired,
  /**
   * Job subtitle of the card
   */
  subtitle: PropTypes.string.isRequired,
  /**
   * Should show arrow or not
   */
  showArrow: PropTypes.bool,
  /**
   * Additional props to pass to the title Typography component
   */
  titleProps: PropTypes.object,
  /**
   * Additional props to pass to the subtitle Typography component
   */
  subtitleProps: PropTypes.object,
};

export default CardJobMinimal;
