import React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery, Grid, Typography, Box } from '@mui/material';
import Swiper from 'swiper';
import 'swiper/css'; // Import Swiper styles

const SwiperSlide = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    paddingBottom: theme.spacing(6),
  },
}));

const NumberText = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
}));

/**
 * Component to display the number swiper
 *
 * @param {Object} props
 */
const SwiperNumber = (props) => {
  const {
    items,
    className,
    numberProps,
    labelProps,
    ...rest
  } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  React.useEffect(() => {
    new Swiper('.swiper-container', {
      slidesPerView: isMd ? 4 : 3,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-container .swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
      },
    });
  }, [isMd]);

  return (
    <Box
      className={className}
      {...rest}
    >
      <Box className="swiper-container">
        <Box className="swiper-wrapper">
          {items.map((item, index) => (
            <SwiperSlide
              key={index}
              className="swiper-slide"
            >
              <Grid
                container
                spacing={1}
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <NumberText
                    variant="h3"
                    align="center"
                    {...numberProps}
                  >
                    {item.number}
                  </NumberText>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="center"
                    {...labelProps}
                  >
                    {item.title}
                  </Typography>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
        </Box>
        <Box className="swiper-pagination" />
      </Box>
    </Box>
  );
};

SwiperNumber.defaultProps = {
  numberProps: {},
  labelProps: {},
};

SwiperNumber.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The array of numbers object which should consist of number and title
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
  })).isRequired,
  /**
   * The additional properties to pass to the number Typography component
   */
  numberProps: PropTypes.object,
  /**
   * The additional properties to pass to the label Typography component
   */
  labelProps: PropTypes.object,
};

export default SwiperNumber;
