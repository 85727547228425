import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Swiper from 'swiper';
import 'swiper/css'; // Import Swiper styles

import { Image } from 'components/atoms';
import clsx from 'clsx';

const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  zIndex: 'auto',
}));

const SwiperSlide = styled(Box)(({ theme }) => ({
  width: 'auto',
}));

const SwiperNav = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  width: 88,
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 4,
  '& .swiper-button-prev, & .swiper-button-next': {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    borderRadius: '100%',
    position: 'relative',
    boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`,
    border: `2px solid ${theme.palette.background.paper}`,
    '&:after': {
      fontSize: 'initial',
      color: theme.palette.background.paper,
    },
  },
  '& .swiper-button-prev': {
    left: 0,
  },
  '& .swiper-button-next': {
    right: 0,
  },
}));

const ImageStyled = styled(Image)(({ theme }) => ({
  objectFit: 'cover',
}));

/**
 * Component to display the image swiper
 *
 * @param {Object} props
 */
const SwiperImage = (props) => {
  const {
    items,
    navigationButtonStyle,
    imageClassName,
    className,
    ...rest
  } = props;

  React.useEffect(() => {
    new Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-container .swiper-button-next',
        prevEl: '.swiper-container .swiper-button-prev',
      },
    });
  }, []);

  return (
    <Root
      className={clsx(
        'swiper-container',
        'swiper-image',
        className,
      )}
      {...rest}
    >
      <Box className="swiper-image__wrapper swiper-wrapper">
        {items.map((item, index) => (
          <SwiperSlide
            className="swiper-image__slide swiper-slide"
            key={index}
          >
            <ImageStyled
              src={item.src}
              alt={item.alt}
              srcSet={item.srcSet}
              lazyProps={{ width: '100%', height: '100%' }}
              className={clsx(
                'swiper-image__item',
                imageClassName,
              )}
            />
          </SwiperSlide>
        ))}
      </Box>
      <SwiperNav className="swiper-image__navigation">
        <Box
          className={clsx(
            'swiper-image__navigation-button',
            'swiper-image__navigation-button--prev',
            'swiper-button-prev',
            navigationButtonStyle,
          )}
        />
        <Box
          className={clsx(
            'swiper-image__navigation-button',
            'swiper-image__navigation-button--next',
            'swiper-button-next',
            navigationButtonStyle,
          )}
        />
      </SwiperNav>
    </Root>
  );
};

SwiperImage.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * External classes for the images
   */
  imageClassName: PropTypes.string,
  /**
   * The array of images object which should consist of src, alt and srcset attributes
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
  })).isRequired,
  /**
   * Styles classes of the navigation button
   */
  navigationButtonStyle: PropTypes.string,
};

export default SwiperImage;
