import React, { useEffect, useRef } from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import Slide from './Slide1';

const Carousel = ({slides}) => {
    const swiperRef = useRef(null);

    useEffect(() => {
        const handleSlideChange = () => {
            if (swiperRef.current && swiperRef.current.swiper) {
                const activeSlide = swiperRef.current.swiper.slides?.[swiperRef.current.swiper.activeIndex];
                if (activeSlide) {
                    activeSlide.classList.add('zoom-in');
                    setTimeout(() => {
                        activeSlide.classList.remove('zoom-in');
                    }, 3000); // Zoom effect lasts 3 second
                }
            }
        };

        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.on('slideChange', handleSlideChange);
        }

        return () => {
            // Ensure swiperRef exists before calling .off()
            if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.off('slideChange', handleSlideChange);
            }
        };
    }, []);

    return (
        <Swiper
            ref={swiperRef}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }} // Auto-slide every 3 seconds
            speed={700}
            loop={true}
            simulateTouch={true}
            modules={[Navigation, Pagination, Autoplay]} // Added Autoplay            
            style={{ backgroundColor: 'white' }}
        >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    <Slide backgroundImage={slide.background} title={slide.title} subtitle={slide.subtitle}/>
                    {/* <Box
                        className="slide-container"
                        sx={{
                            // height: '100vh',
                            height: {
                                xs: '30vh', // 40vh on extra-small screens (mobile)
                                sm: '30vh', // 50vh on small screens (tablets)
                                md: '70vh', // 50vh on medium screens (small desktops)
                                lg: '90vh', // 90vh on large screens (desktops)
                                xl: '90vh', // 90vh on extra-large screens
                            },
                            backgroundImage: slide.background,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            paddingLeft: '10%',
                            color: 'white',
                            position: 'relative',
                        }}
                    >
                        <Container maxWidth={false} sx={{ padding: '0 20%', textAlign: 'left' }}>
                            <Typography variant="h2" gutterBottom>
                                {slide.title}
                            </Typography>
                            <Typography variant="h7" gutterBottom>
                                {slide.subtitle}
                            </Typography>
                            <Box mt={4} display="flex" gap={2}>
                                <Button
                                    variant="contained"
                                    color="primary"                                
                                >
                                    Demo
                                </Button>
                                <Button variant="outlined" color="secondary">
                                    Register
                                </Button>
                            </Box>
                        </Container>
                    </Box> */}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default Carousel;
