import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Section } from 'components/organisms';
import clsx from 'clsx';

// Styled components
const Root = styled('div')(({ theme, backgroundColor }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'visible',
  background: backgroundColor || theme.palette.indigo[900],
}));

const HeroWrapper = styled('div')({
  zIndex: 2,
});

const HeroCover = styled('div')(({ theme, backgroundImg, disbaleCoverOpacity }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: disbaleCoverOpacity ? 1 : 0.2,
  width: '100%',
  height: '100%',
  zIndex: 1,
  backgroundImage: `url(${backgroundImg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

/**
 * Component to display the background hero
 *
 * @param {Object} props
 */
const HeroBackground = (props) => {
  const {
    children,
    disbaleCoverOpacity,
    backgroundImg,
    backgroundPosition,
    backgroundColor,
    contentSectionClassName,
    className,
    ...rest
  } = props;

  return (
    <Root
      backgroundColor={backgroundColor}
      className={clsx('hero-background', className)}
      {...rest}
    >
      <HeroWrapper className="hero-background__wrapper">
        <Section
          className={clsx(
            contentSectionClassName ? contentSectionClassName : '',
            'hero-background__section',
          )}
        >
          {children}
        </Section>
      </HeroWrapper>
      <HeroCover
        backgroundImg={backgroundImg}
        disbaleCoverOpacity={disbaleCoverOpacity}
        className={clsx('hero-background__cover')}
      />
    </Root>
  );
};

HeroBackground.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Children to place inside the hero
   */
  children: PropTypes.node,
  /**
   * Background image of the hero
   */
  backgroundImg: PropTypes.string,
  /**
   * Background color of the hero
   */
  backgroundColor: PropTypes.string,
  /**
   * Background position of the hero
   */
  backgroundPosition: PropTypes.string,
  /**
   * Custom classes for the content section
   */
  contentSectionClassName: PropTypes.string,
  /**
   * Should disable hero cover opacity
   */
  disbaleCoverOpacity: PropTypes.bool,
};

export default HeroBackground;
