import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { Topbar } from './components';

const Root = styled('div')(({ theme }) => ({
  // Add any styles you want to apply globally
}));

const Content = styled('main')(({ theme }) => ({
  height: '100%',
}));

const Minimal = ({ themeMode, children, className }) => {
  return (
    <Root className={className}>
      <Topbar themeMode={themeMode} />
      <Divider />
      <Content>{children}</Content>
    </Root>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  themeMode: PropTypes.string.isRequired,
};

export default Minimal;
