import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, Button, useMediaQuery } from '@mui/material';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  videoIframe: {
    boxShadow: `0 5px 15px 0 ${theme.palette.cardShadow}`,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
    },
  },
}));

const VideoSection = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const scrollTo = (id) => {
    setTimeout(() => {
      const element = document.querySelector(`/#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container justify="space-between" spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={6} data-aos={'fade-up'}>
          <SectionHeader
            title="Local Offer Connect Digital"
            subtitle="Enable every person to create offer to sell products and service for buyers to search and consume"
            ctaGroup={[
              <Button
                variant="contained"
                color="primary"
                size={isMd ? 'large' : 'medium'}
                href="/#home-contact"
              >
                Contact us
              </Button>,
            ]}
            align={isMd ? 'left' : 'center'}
            disableGutter={true}
            titleVariant="h3"
          />
        </Grid>
        <Grid item xs={12} md={6} data-aos={'fade-up'}>
        <Image
          width="100%"
          height="315"
          src={'/assets/images/home/welcome.jpg'}
          alt={'Solutions'}
        />
        </Grid>
      </Grid>
    </div>
  );
};

/*
<iframe
  className={classes.videoIframe}
  title="video"
  width="100%"
  height="315"
  src="https://www.youtube.com/embed/ClIbn_IF31U"
  frameBorder="0"
  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
/>
*/

VideoSection.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default VideoSection;
