import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Image } from 'components/atoms';

const ImageGrid = styled('div')(({ theme }) => ({
  padding: '0.25rem',
  width: '150%',
  boxShadow: '0 1.5rem 4rem rgba(22,28,45,.1)',
  background: theme.palette.background.paper,
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    verticalAlign: 'middle',
    borderStyle: 'none',
  },
}));

const ImageGridFirstItem = styled(ImageGrid)(({ theme }) => ({
  marginTop: '4rem',
}));

const ImageGridLastItem = styled(ImageGrid)(({ theme }) => ({
  marginTop: '6rem',
  float: 'right',
}));

/**
 * Component to display the overlapped images
 *
 * @param {Object} props
 */
const OverlapedImages = (props) => {
  const { image1, image2, image3, className, ...rest } = props;

  return (
    <Grid
      container
      className={className}
      {...rest}
    >
      <Grid item xs={4} className="overlaped-images__item-container">
        <ImageGridFirstItem className="overlaped-images__item-wrapper">
          <Image
            src={image1.src}
            srcSet={image1.srcset}
            alt={image1.alt}
            className="overlaped-images__item-image"
          />
        </ImageGridFirstItem>
      </Grid>
      <Grid item xs={4} className="overlaped-images__item-container">
        <ImageGrid className="overlaped-images__item-wrapper">
          <Image
            src={image2.src}
            srcSet={image2.srcset}
            alt={image2.alt}
            className="overlaped-images__item-image"
          />
        </ImageGrid>
      </Grid>
      <Grid item xs={4} className="overlaped-images__item-container">
        <ImageGridLastItem className="overlaped-images__item-wrapper">
          <Image
            src={image3.src}
            srcSet={image3.srcset}
            alt={image3.alt}
            className="overlaped-images__item-image"
          />
        </ImageGridLastItem>
      </Grid>
    </Grid>
  );
};

OverlapedImages.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Image item - Object of src, srcset, and alt properties
   */
  image1: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcset: PropTypes.string,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Image item - Object of src, srcset, and alt properties
   */
  image2: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcset: PropTypes.string,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Image item - Object of src, srcset, and alt properties
   */
  image3: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcset: PropTypes.string,
    alt: PropTypes.string.isRequired,
  }).isRequired,
};

export default OverlapedImages;
