import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Typography, IconButton } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { IconAlternate } from 'components/molecules';
import { CardBase } from 'components/organisms';

// Styled components
const StyledCardBase = styled(CardBase)(({ theme, align, color }) => ({
  height: '100%',
  width: '100%',
  '& .card-category-link__icon-button': {
    display: 'flex',
    width: '100%',
    justifyContent: align === 'center' ? 'center' : align === 'right' ? 'flex-end' : 'flex-start',
  },
}));

const FontWeightTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
}));

/**
 * Component to display the category link card
 *
 * @param {Object} props
 */
const CardCategoryLink = props => {
  const {
    fontIconClass,
    color,
    title,
    subtitle,
    href,
    align = 'left',
    className,
    iconAlternateProps,
    titleProps,
    subtitleProps,
    ...rest
  } = props;

  return (
    <StyledCardBase
      className={className}
      align={align}
      color={color}
      {...rest}
    >
      <Grid container spacing={2} className="card-category-link__wrapper">
        <Grid item container justifyContent={align} size={12}>
          <IconAlternate
            fontIconClass={fontIconClass}
            color={color}
            size="medium"
            {...iconAlternateProps}
          />
        </Grid>
        <Grid item size={12}>
          <FontWeightTypography
            variant="h6"
            align={align}
            {...titleProps}
          >
            {title}
          </FontWeightTypography>
        </Grid>
        {subtitle && (
          <Grid item size={12}>
            <Typography
              variant="subtitle1"
              align={align}
              color="textSecondary"
              {...subtitleProps}
            >
              {subtitle}
            </Typography>
          </Grid>
        )}
      </Grid>
      <a
        href={href}
        className="card-category-link__item"
      >
        <IconButton className="card-category-link__icon-button">
          <ArrowRightAltIcon
            style={{ color: color[500] }}
          />
        </IconButton>
      </a>
    </StyledCardBase>
  );
};

CardCategoryLink.defaultProps = {
  align: 'left',
  href: '#',
  iconAlternateProps: {},
  titleProps: {},
  subtitleProps: {},
};

CardCategoryLink.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Promo title to show inside the card
   */
  title: PropTypes.string.isRequired,
  /**
   * Promo subtitle to show inside the card
   */
  subtitle: PropTypes.string,
  /**
   * Promo description to show inside the card
   */
  href: PropTypes.string.isRequired,
  /**
   * Promo font icon class name to show inside the card
   */
  fontIconClass: PropTypes.string,
  /**
   * Promo icon color to show inside the card
   */
  color: PropTypes.object.isRequired,  // Adjusted to accept object as `color` from `colors`
  /**
   * The content alignment
   */
  align: PropTypes.oneOf(['left', 'right', 'center']),
  /**
   * Additional props to pass to the IconAlternate component
   */
  iconAlternateProps: PropTypes.object,
  /**
   * Additional props to pass to the title Typography component
   */
  titleProps: PropTypes.object,
  /**
   * Additional props to pass to the subtitle Typography component
   */
  subtitleProps: PropTypes.object,
};

export default CardCategoryLink;
