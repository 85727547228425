import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase',
  },
  textYellow: {
    color: 'yellow',
  },
}));

const Welcome = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const scrollTo = (id) => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };


  return (
    <div className={clsx('jarallax', className)} {...rest} >
      <SectionHeader
        title={<span className={classes.textWhite}>Accelerate Your Digital Journey with MindsClik</span>}
        titleVariant="h2"
        subtitle={<span className={classes.textYellow}>MindsClik is digital consultancy that engages with established businesses and startups in defining their Digital Journey Roadmap, to be their technology partner to deliver and operate</span>}
        ctaGroup={[
          <Button variant="contained" color="primary" size="large"
          onClick={() => scrollTo('home-contact')}>
            Contact us
          </Button>,
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};



Welcome.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Welcome;
