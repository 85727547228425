import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia } from '@mui/material';
import { styled } from '@mui/system';
import clsx from 'clsx';

// Define the root style
const Root = styled(Card)(({ theme, withShadow, noShadow, noBorder, noBg, liftUp }) => ({
  height: '100%',
  width: '100%',
  boxShadow: noShadow ? 'none' : withShadow ? `0 2px 10px 0 ${theme.palette.cardShadow}` : 'none',
  background: noBg ? 'transparent' : undefined,
  border: noBorder ? 0 : undefined,
  transition: 'box-shadow .25s ease, transform .25s ease',
  ...(liftUp && {
    boxShadow: '0 1.5rem 2.5rem rgba(22,28,45,.1),0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important',
    transform: 'translate3d(0,-5px,0)',
  }),
}));

// Styles for different properties
const mediaStyles = {
  position: 'relative',
  height: 300,
};

const contentStyles = ({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4, 2),
  '&:last-child': {
    padding: theme.spacing(4, 2),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6, 3),
    '&:last-child': {
      padding: theme.spacing(6, 3),
    },
  },
});

const CardProduct = (props) => {
  const {
    mediaClassName,
    withShadow,
    noShadow,
    noBorder,
    noBg,
    liftUp,
    cardContent,
    mediaContent,
    align,
    className,
    ...rest
  } = props;

  const classes = {
    content: clsx(
      align === 'left' && 'align-items-start',
      align === 'right' && 'align-items-end',
      align === 'center' && 'align-items-center',
    ),
  };

  return (
    <Root
      className={clsx(
        'card-product',
        className
      )}
      withShadow={withShadow}
      noShadow={noShadow}
      noBorder={noBorder}
      noBg={noBg}
      liftUp={liftUp}
      {...rest}
    >
      <CardMedia className={clsx('card-product__media', mediaClassName)} sx={mediaStyles}>
        {mediaContent}
      </CardMedia>
      <CardContent className={clsx('card-product__content', classes.content)} sx={contentStyles}>
        {cardContent}
      </CardContent>
    </Root>
  );
};

CardProduct.defaultProps = {
  align: 'left',
};

CardProduct.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * External classes for the media
   */
  mediaClassName: PropTypes.string,
  /**
   * Whether to show custom shadow
   */
  withShadow: PropTypes.bool,
  /**
   * Whether to render the card without shadow
   */
  noShadow: PropTypes.bool,
  /**
   * Whether to hide the card borders
   */
  noBorder: PropTypes.bool,
  /**
   * Whether to show transparent background
   */
  noBg: PropTypes.bool,
  /**
   * Whether to lift up on hover
   */
  liftUp: PropTypes.bool,
  /**
   * The Card content
   */
  cardContent: PropTypes.any.isRequired,
  /**
   * The Card Media content
   */
  mediaContent: PropTypes.any.isRequired,
  /**
   * The content alignment
   */
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

export default CardProduct;
