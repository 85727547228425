import React from 'react';
import { makeStyles } from '@mui/styles';
import { Section, SectionAlternate } from 'components/organisms';
import {
  Contact,
  Gallery,
  Hero,
  Partners,
  Story,
  Team,
  WhoWeAre,
} from './components';

import { team, companies, mapData, gallery } from './data';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  sectionPartners: {
    boxShadow: '0 5px 20px 0 rgba(90, 202, 157, 0.05)',
    '& .section-alternate__content': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
}));

const About = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet><title>About Us</title></Helmet>
      <Hero id="about"/>
      <Section id="about-story">
        <Story />
      </Section>
    <SectionAlternate className={classes.sectionNoPaddingTop}>
        <WhoWeAre />
      </SectionAlternate>
      {/* <Section id="about-team" className={classes.sectionNoPaddingTop}>
        <Team data={team} />
      </Section> */}
    {/*  <SectionAlternate className={classes.sectionPartners}>
        <Partners data={companies} />
      </SectionAlternate> */}
      <Section id="about-contact" className={classes.sectionNoPaddingTop}>
        <Contact id="about-contact" data={mapData} />
      </Section>
      
    {/*  <SectionAlternate>
        <Gallery data={gallery} />
      </SectionAlternate>  */}
    </div>
  );
};

export default About;
