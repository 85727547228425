import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import clsx from 'clsx';

// Styled component
const Root = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
});

/**
 * Component to display the map
 *
 * @param {Object} props
 */
const Map = ({ zoom=10, center=[0,0], pins, className, ...rest }) => {

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const L = require('leaflet');
      delete L.Icon.Default.prototype._getIconUrl;

      const markerIcon2x = require('assets/images/leaflet-assets/marker-icon-2x.png');
      const markerIcon = require('assets/images/leaflet-assets/marker-icon.png');
      const markerShadow = require('assets/images/leaflet-assets/marker-shadow.png');

      L.Icon.Default.mergeOptions({
        iconRetinaUrl: typeof markerIcon2x === 'object' ? markerIcon2x.default : markerIcon2x,
        iconUrl: typeof markerIcon === 'object' ? markerIcon.default : markerIcon,
        shadowUrl: typeof markerShadow === 'object' ? markerShadow.default : markerShadow,
      });
    }
  }, []);

  // Return null if window is undefined (i.e., server-side rendering)
  if (typeof window === 'undefined') {
    return null;
  }

  const { MapContainer, TileLayer, Marker } = require('react-leaflet');

  return (
    <Root className={clsx('map', className)} {...rest}>
      <MapContainer zoom={zoom} center={center} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          className="map__tile-layer"
          detectRetina={true}
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {pins &&
          pins.length > 0 &&
          pins.map((item, i) => (
            <Marker
              className="map__marker"
              position={[item.location.y, item.location.x]}
              key={i}
            />
          ))}
      </MapContainer>
    </Root>
  );
};

// Map.defaultProps = {
//   zoom: 10,
//   center: [0, 0],
// };

Map.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Map zoom
   */
  zoom: PropTypes.number,
  /**
   * Map center
   */
  center: PropTypes.arrayOf(PropTypes.number).isRequired,
  /**
   * Data of the locations. Example: [{ location: { x: number, y: number } }]
   */
  pins: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
      }).isRequired,
    })
  ),
};

export default Map;
