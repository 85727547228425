import React from 'react';
import PropTypes from 'prop-types';
import { Grid2 as Grid, Button, colors } from '@mui/material';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  textBlue: {
    color: 'navyblue',
//    textTransform: 'uppercase',
  },
}));

const Features = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <SectionHeader title="What we do" fadeUp
        subtitle={<span className={classes.textBlue}>Consulting Services | eCommerce and Content Solutions | Your Startup Store | Web & Mobile Apps</span>}
        disableGutter
        data-aos="fade-up"
      />


      <Grid container spacing={4}>
        {data.map((item, index) => (
          <Grid
            key={index}
            container
            alignItems="center"
            direction="column"
            size={{xs:12, sm:6, md:4}}            
            data-aos="fade-up"
          >
            <CardBase liftUp variant="outlined" withShadow>
              <DescriptionListIcon
                icon={
                  <IconAlternate
                    fontIconClass={item.icon}
                    color={colors.indigo}
                  />
                }
                title={item.title}
                subtitle={item.description}
              />
            </CardBase>
          </Grid>
        ))}
        <Grid container size={12} justifyContent="center">
          <Button variant="contained" size="large" color="primary" href="/solution-offering">
            Solution Details
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

Features.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Features;
