import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@mui/material';
import { CardBase } from 'components/organisms';

const StyledDot = styled('span')(({ theme, size, color }) => ({
  display: 'inline-block',
  width: theme.spacing(size),
  height: theme.spacing(size),
  background: color || theme.palette.text.primary,
  borderRadius: '100%',
  marginRight: theme.spacing(1),
}));

/**
 * Component to display the job card
 *
 * @param {Object} props
 */
const CardJob = props => {
  const {
    badgeColor,
    badgeTitle,
    jobTitle,
    jobLocation,
    jobType,
    jobDate,
    companyLogo,
    companyName,
    className,
    ...rest
  } = props;

  return (
    <CardBase
      className={className}
      align="left"
      {...rest}
    >
      <Grid container spacing={2}>
        <Grid item container alignItems="center" xs={12}>
          <StyledDot size={2} color={badgeColor} />
          <Typography component="span" variant="body2" color="textPrimary">
            {badgeTitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {jobTitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item container alignItems="center" xs={12}>
          <Typography component="span" variant="subtitle1" color="textSecondary">
            {jobLocation}
          </Typography>
          <StyledDot size={0.5} sx={{ margin: '0 8px' }} />
          <Typography component="span" variant="subtitle1" color="textSecondary">
            {jobType}
          </Typography>
        </Grid>
      </Grid>
      <ListItem disableGutters>
        <ListItemAvatar>
          <Avatar src={companyLogo} alt={companyName} />
        </ListItemAvatar>
        <ListItemText primary={companyName} secondary={jobDate} />
      </ListItem>
    </CardBase>
  );
};

CardJob.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Badge color of the job card
   */
  badgeColor: PropTypes.string.isRequired,
  /**
   * Badge title of the job card
   */
  badgeTitle: PropTypes.string.isRequired,
  /**
   * Job title of the card
   */
  jobTitle: PropTypes.string.isRequired,
  /**
   * Job location of the card
   */
  jobLocation: PropTypes.string.isRequired,
  /**
   * Job type of the card
   */
  jobType: PropTypes.string.isRequired,
  /**
   * Job date of the card
   */
  jobDate: PropTypes.string.isRequired,
  /**
   * Company logo of the card
   */
  companyLogo: PropTypes.string.isRequired,
  /**
   * Company name of the card
   */
  companyName: PropTypes.string.isRequired,
};

export default CardJob;
