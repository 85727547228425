import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { CardBase } from 'components/organisms';
import { red } from '@mui/material/colors';

// Define the root element style
const Root = styled(CardBase)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '&:hover': {
    borderRight: `${theme.spacing(1)}px solid ${red[500]}`,
  },
}));

// Define other styled components
const Dot = styled('span')(({ theme, size }) => ({
  display: 'inline-block',
  width: theme.spacing(size),
  height: theme.spacing(size),
  background: theme.palette.text.primary,
  borderRadius: '100%',
  marginRight: theme.spacing(1),
}));

const JobTitle = styled(Typography)({
  fontWeight: 700,
});

const Tag = styled('span')(({ theme, background, borderColor }) => ({
  display: 'inline-block',
  padding: theme.spacing(0.5),
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${borderColor}`,
  backgroundColor: background,
}));

/**
 * Component to display the job card
 *
 * @param {Object} props
 */
const CardJobTag = props => {
  const { badgeColor, badgeTitle, jobTitle, jobLocation, jobType, className, ...rest } = props;

  return (
    <Root className={className} align="left" {...rest}>
      <Grid container spacing={2}>
        <Grid item container alignItems="center" xs={12}>
          <Tag background={badgeColor} borderColor={badgeColor}>
            <Typography component="span" variant="body2" color="white">
              {badgeTitle}
            </Typography>
          </Tag>
        </Grid>
        <Grid item xs={12}>
          <JobTitle variant="h6">{jobTitle}</JobTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item container alignItems="center" xs={12}>
          <Typography component="span" variant="subtitle1" color="textSecondary">
            {jobLocation}
          </Typography>
          <Dot size={0.5} />
          <Typography component="span" variant="subtitle1" color="textSecondary">
            {jobType}
          </Typography>
        </Grid>
      </Grid>
    </Root>
  );
};

CardJobTag.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Badge color of the job card
   */
  badgeColor: PropTypes.string.isRequired,
  /**
   * Badge title of the job card
   */
  badgeTitle: PropTypes.string.isRequired,
  /**
   * Job title of the card
   */
  jobTitle: PropTypes.string.isRequired,
  /**
   * Job location of the card
   */
  jobLocation: PropTypes.string.isRequired,
  /**
   * Job type of the card
   */
  jobType: PropTypes.string.isRequired,
};

export default CardJobTag;
