import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  Popover,
  Typography,
  IconButton,
  Button,
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { Image, DarkModeToggler } from 'components/atoms';
import clsx from 'clsx';

const LogoContainer = styled(Box)(({ theme }) => ({
  width: 140,
  [theme.breakpoints.up('md')]: {
    width: 160,
  },
}));

const LogoImage = styled(Image)(({ theme }) => ({
  width: '100%',
  height: '100%',
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const PopoverStyled = styled(Popover)(({ theme }) => ({
  padding: theme.spacing(4),
  border: theme.spacing(2),
  boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
  minWidth: 350,
  marginTop: theme.spacing(2),
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover > .menu-item, &:hover svg': {
    color: theme.palette.primary.dark,
  },
  '&.menu-item--no-dropdown': {
    paddingRight: 0,
  },
}));

const ListItemActive = styled(ListItemStyled)(({ theme }) => ({
  '& > .menu-item': {
    color: theme.palette.primary.dark,
  },
}));

const ListItemText = styled(Typography)(({ theme }) => ({
  flex: '0 0 auto',
  marginRight: theme.spacing(2),
  whiteSpace: 'nowrap',
}));

const ExpandMoreIconStyled = styled(ExpandMoreIcon)(({ theme }) => ({
  transform: 'rotate(0deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.short,
  }),
  '&.expandOpen': {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
}));

const Topbar = ({ themeMode, themeToggler, onSidebarOpen, pages, className, ...rest }) => {  
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));


  const handleClick = (event, popoverId) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const landings = pages.landings;
  const about = pages.about;

  const MenuGroup = ({ item }) => (
    <List disablePadding>
      <ListItem disableGutters sx={{padding:1, minWidth: 250}}>
        <Typography
          variant="body2"
          color="primary"
          sx={{ textTransform: 'uppercase' }}
        >
          {item.groupTitle}
        </Typography>
      </ListItem>
      {item.pages.map((page, i) => (
        <ListItem disableGutters key={i} sx={{padding:1, minWidth: 250}}>
          <Typography
            variant="body1"
            component={'a'}
            href={page.href}
            color="textSecondary"
            onClick={handleClose}
            sx={{ textDecoration: 'none', '&:hover': { color: 'primary.dark' } }}
          >
            {page.title}
          </Typography>
        </ListItem>
      ))}
    </List>
  );

  const LandingPages = () => {
    const { services } = landings.children;
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <MenuGroup item={services} />
      </Box>
    );
  };

  const AboutPages = () => {
    const { company } = about.children;
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <MenuGroup item={company} />
      </Box>
    );
  };

  const renderPages = id => {
    if (id === 'services') {
      return <LandingPages />;
    }
    if (id === 'about') {
      return <AboutPages />;
    }
  };

  return (
    <Toolbar disableGutters sx={{ zIndex: 999, maxWidth: '1200px', width: '100%', margin: '0 auto', padding: '0 16px' }} {...rest}>
      <LogoContainer>
        <a href="/" title="home">
          <LogoImage
            src={themeMode === 'light' ? '/assets/logos/logo.png' : '/assets/logos/logo.png'}
            alt="Lokey"
            lazy={false}
          />
        </a>
      </LogoContainer>
      <Box sx={{ flexGrow: 1 }} />
      { !isSmDown && (
        <NavigationContainer>
          {[/*landings, about TODO_SP: Add the pages here */].map((page, i) => (
            <div key={page.id}>
              <ListItemStyled
                aria-describedby={page.id}
                onClick={e => handleClick(e, page.id)}
                className={clsx({ 'menu-item--no-dropdown': !page.pages })}
              >
                <ListItemText
                  variant="body1"
                  color="textPrimary"
                  sx={{ textDecoration: 'none' }}
                >
                  {page.title}
                </ListItemText>
                <ListItemIcon>
                  <ExpandMoreIconStyled
                    className={openedPopoverId === page.id ? 'expandOpen' : ''}
                    fontSize="small"
                  />
                </ListItemIcon>
              </ListItemStyled>
              <PopoverStyled
                elevation={1}
                id={page.id}
                open={openedPopoverId === page.id}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div>{renderPages(page.id)}</div>
              </PopoverStyled>
            </div>
          ))}
          <ListItemStyled className="menu-item--no-dropdown">
            <DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />
          </ListItemStyled>
        </NavigationContainer>
      )}
      {!isMdUp && (
        <>
        {/* <DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} /> */}
        <IconButton
          sx={{ marginLeft: 2, padding: 0, '&:hover': { background: 'transparent' } }}
          onClick={onSidebarOpen}
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
        </>
      )}
    </Toolbar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Topbar;
