export const team = [
  {
    title: 'Strategy & Solutions',
    authorPhoto: {
      src: '/assets/images/about/photos/sunil_parte.jpg',
      //srcSet: '/assets/about/sunil_parte.jpg',
    },
    authorName: 'Sunil Parte',
  },
  {
    title: 'Technology & Engineering',
    authorPhoto: {
      src: '/assets/images/about/photos/nilan_das.png',
    },
    authorName: 'Nilan Das',
  },
];

export const companies = [
  {
    name: 'Airbnb',
    logo: 'https://assets.maccarianagency.com/the-front/logos/airbnb.svg',
  },
  {
    name: 'Coinbase',
    logo: 'https://assets.maccarianagency.com/the-front/logos/coinbase.svg',
  },
  {
    name: 'Dribbble',
    logo: 'https://assets.maccarianagency.com/the-front/logos/dribbble.svg',
  },
];

export const mapData = [
  {
    location: {
      y: 12.979193,
      x: 77.724357,
      address: 'Bengaluru, 560066',
    },
  },
/*  {
    location: {
      y: 45.419211,
      x: 9.021383,
      address: 'Via Rocca de Baldi 95, 440368',
    },
  },
  {
    location: {
      y: 45.473211,
      x: 9.298383,
      address: 'Via Firenze 134, 45588',
    },
  },
  {
    location: {
      y: 45.461211,
      x: 9.000383,
      address: 'Via Cavour 29, 201558',
    },
  },
  {
    location: {
      y: 45.413211,
      x: 9.398383,
      address: 'Via Bologna 33, 220156',
    },
  },
  {
    location: {
      y: 45.569211,
      x: 9.128383,
      address: 'Vicolo Tre Marchetti 127, 350125',
    },
  },
  {
    location: {
      y: 45.483211,
      x: 9.148383,
      address: 'Via Lombardi 146, 45830',
    },
  },
  {
    location: {
      y: 45.313211,
      x: 9.012383,
      address: 'Via Guantai Nuovi 99, 56989',
    },
  }, */
];

export const gallery = [
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1@2x.jpg 2x',
    },
    title: 'gallery',
    cols: 1,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery3.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery3@2x.jpg 2x',
    },
    title: 'gallery',
    cols: 3,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery2.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery2@2x.jpg 2x',
    },
    title: 'gallery',
    cols: 3,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/coworking/place3.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/coworking/place3@2x.jpg 2x',
    },
    title: 'gallery',
    cols: 1,
  },
];
