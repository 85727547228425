import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NoSsr, Box } from '@mui/material';

/**
 * Component to display the icon
 *
 * @param {Object} props
 */
const Icon = ({ fontIconClass, size='small', fontIconColor, className, ...rest }) => {
  const fontSizeMap = {
    extraSmall: 10,
    small: 20,
    medium: 30,
    large: 40,
  };

  return (
    <NoSsr>
      <Box
        component="i"
        className={clsx('icon', fontIconClass, className)}
        sx={{
          fontSize: fontSizeMap[size],
          color: fontIconColor,
        }}
        {...rest}
      />
    </NoSsr>
  );
};

// Icon.defaultProps = {
//   size: 'small',
// };

Icon.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The classes of the font icon
   */
  fontIconClass: PropTypes.string.isRequired,
  /**
   * Source set for the responsive images
   */
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large']),
  /**
   * Color of the icon
   */
  fontIconColor: PropTypes.string,
};

export default Icon;
