import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import Icon from 'components/atoms/Icon'; // Ensure that Icon is updated for MUI v6

/**
 * Component to display the icon text
 *
 * @param {Object} props
 */
const IconText = (props) => {
  const {
    title,
    color,
    fontIconClass,
    className,
    iconProps,
    typographyProps,
    ...rest
  } = props;

  return (
    <Box
      className={clsx('icon-text', className)}
      display="inline-flex"
      flexWrap="nowrap"
      alignItems="center"
      width="100%"
      {...rest}
    >
      <Icon
        className="icon-text__icon"
        size="small"
        fontIconClass={fontIconClass}
        fontIconColor={color}
        {...iconProps}
      />
      <Typography
        noWrap
        variant="subtitle1"
        color="textPrimary"
        sx={{ ml: 1 }} // Replaces the `theme.spacing(1)` margin-left
        className="icon-text__typography"
        {...typographyProps}
      >
        {title}
      </Typography>
    </Box>
  );
};

IconText.defaultProps = {
  iconProps: {},
  typographyProps: {},
};

IconText.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The classes of the font icon
   */
  fontIconClass: PropTypes.string.isRequired,
  /**
   * Source set for the responsive images
   */
  color: PropTypes.string,
  /**
   * Title of the icon-text
   */
  title: PropTypes.string.isRequired,
  /**
   * Additional properties to pass to the Icon component
   */
  iconProps: PropTypes.object,
  /**
   * Additional properties to pass to the Typography component
   */
  typographyProps: PropTypes.object,
};

export default IconText;
