import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import { CardBase } from 'components/organisms';
import { LearnMoreLink } from 'components/atoms';

const CompanyAvatar = styled(Avatar)(({ theme }) => ({
  width: 60,
  height: 60,
}));

const Dot = styled('span')(({ theme, size = 1 }) => ({
  display: 'inline-block',
  width: theme.spacing(size),
  height: theme.spacing(size),
  background: theme.palette.text.primary,
  borderRadius: '100%',
  marginRight: theme.spacing(1),
}));

/**
 * Component to display the job card company
 *
 * @param {Object} props
 */
const CardJobCompany = props => {
  const {
    jobTitle,
    jobLocation,
    companyLogo,
    companyName,
    jobsCount,
    companyInfo,
    className,
    ...rest
  } = props;

  return (
    <CardBase
      className={className}
      align="left"
      {...rest}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CompanyAvatar
            src={companyLogo}
            alt={companyName}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {companyName}
          </Typography>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Typography component="span" variant="subtitle1" color="textPrimary">
            {jobTitle}
          </Typography>
          <Dot size={0.5} sx={{ margin: '0 8px' }} />
          <Typography component="span" variant="subtitle1" color="textPrimary">
            {jobLocation}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LearnMoreLink title={jobsCount} variant="subtitle1" />
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" variant="body2" color="textSecondary">
            {companyInfo}
          </Typography>
        </Grid>
      </Grid>
    </CardBase>
  );
};

CardJobCompany.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Job title of the card
   */
  jobTitle: PropTypes.string.isRequired,
  /**
   * Job location of the card
   */
  jobLocation: PropTypes.string.isRequired,
  /**
   * Company logo of the card
   */
  companyLogo: PropTypes.string.isRequired,
  /**
   * Company name of the card
   */
  companyName: PropTypes.string.isRequired,
  /**
   * Count of the jobs in the the card
   */
  jobsCount: PropTypes.string.isRequired,
  /**
   * Company info in the card
   */
  companyInfo: PropTypes.string.isRequired,
};

export default CardJobCompany;
