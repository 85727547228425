import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import clsx from 'clsx';

// Styled components
const Root = styled('div')(({ theme }) => ({
  position: 'relative',
}));

const Image = styled('div')(({ backgroundImage }) => ({
  position: 'absolute',
  objectFit: 'cover',
  fontFamily: 'object-fit: cover;', // This is not a valid CSS property but seems to be a workaround for a plugin
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -1,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundImage: `url(${backgroundImage})`,
}));

/**
 * Component to display the Parallax backgrounds
 *
 * @param {Object} props
 */
const Parallax = props => {
  const { backgroundImage, children, className, ...rest } = props;

  useEffect(() => {
    const jarallaxElems = document.querySelectorAll('.jarallax');
    if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
      return;
    }

    const jarallax = require('jarallax').jarallax;
    jarallax(jarallaxElems, { speed: 0.2 });
  }, []);

  return (
    <Root
      className={clsx('jarallax', 'parallax', className)}
      data-jarallax
      data-speed="0.2"
      {...rest}
    >
      <Image
        className={clsx('jarallax-img', 'parallax__image')}
        backgroundImage={backgroundImage}
      />
      {children}
    </Root>
  );
};

Parallax.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The content
   */
  children: PropTypes.node,
  /**
   * The parallax background image
   */
  backgroundImage: PropTypes.string.isRequired,
};

export default Parallax;
