import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { CardBase, DescriptionListIcon } from 'components/organisms';

// Styled CardBase component with MUI v6
const StyledCardBase = styled(CardBase)(({ theme }) => ({
  height: '100%',
  width: '100%',
}));

/**
 * Component to display the category card
 *
 * @param {Object} props
 */
const CardCategory = (props) => {
  const { icon, title, align, className, ...rest } = props;

  return (
    <StyledCardBase className={className} {...rest}>
      <DescriptionListIcon icon={icon} title={title} align={align} />
    </StyledCardBase>
  );
};

CardCategory.defaultProps = {
  align: 'center',
};

CardCategory.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Icon to show inside the category card
   */
  icon: PropTypes.node.isRequired,
  /**
   * Category title to show inside the category card
   */
  title: PropTypes.string.isRequired,
  /**
   * The content alignment
   */
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

export default CardCategory;
