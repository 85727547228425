import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Divider } from '@mui/material';
import { Section } from 'components/organisms';
import clsx from 'clsx';

// Styled components
const Root = styled('div')({
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const Hero = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  maxWidth: theme.layout.contentWidth,
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
  },
}));

const HeroLeftSide = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 8),
  },
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(3, 2),
  },
}));

const HeroRightSide = styled('div')(({ theme }) => ({
  maxWidth: '50%',
  flex: '0 0 50%',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    flex: '0 0 100%',
    height: '300px',
  },
}));

const HeroCover = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '50vw',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const HeroImageContainer = styled('div')({
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const HeroImage = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '0%',
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    shapeOutside: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
    clipPath: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
  },
}));

/**
 * Component to display the shaped hero
 *
 * @param {Object} props
 */
const HeroShaped = (props) => {
  const { leftSide, rightSide, className, isDivider=true, ...rest } = props;

  return (
    <Root className={clsx('hero-shaped', className)} {...rest}>
      <Hero className="hero-shaped__wrapper">
        <HeroLeftSide className="hero-shaped__left-side">
          {leftSide}
        </HeroLeftSide>
        <HeroRightSide className="hero-shaped__right-side">
          <HeroCover className="hero-shaped__cover">
            <HeroImageContainer className="hero-shaped__image-container">
              <HeroImage className="hero-shaped__image">
                {rightSide}
              </HeroImage>
            </HeroImageContainer>
          </HeroCover>
        </HeroRightSide>
      </Hero>
      {isDivider && <Divider />}
    </Root>
  );
};

HeroShaped.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Children to place inside the section right side
   */
  rightSide: PropTypes.node.isRequired,
  /**
   * Children to place inside the section left side
   */
  leftSide: PropTypes.node.isRequired,
};

export default HeroShaped;
