import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography,  } from '@mui/material';

import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

const ProfileDelete = () => {
  const classes = useStyles();
  
  return (
    <div>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <SectionHeader
            label="profile delete"
            title="Delete Account"
            subtitle={
              <span>
                Sorry to see You thinking of exiting Lokey Platform {' '}
              </span>
            }
            titleProps={{
              variant: 'h3',
            }}
            labelProps={{
              color: 'secondary',
              className: classes.label,
              variant: 'h5',
            }}
            ctaGroup={[
              <Button
                size="large"
                variant="contained"
                color="primary"
                href="/"
              >
                Go Home
              </Button>,
            ]}
            disableGutter
          />

          <Typography variant="body" mt={4}>
            Kindly drop an email to support@mindsclik.com providing your mobile number and reason for exit.{' '}
          </Typography>

          <Typography variant="body">
            Our team will get in connect with you and on proper validation delete your account details permanently.
          </Typography>
        </div>

      </Section>
    </div>
  );
};

export default ProfileDelete;
