import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Toolbar } from '@mui/material';
import { Image } from 'components/atoms';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  maxWidth: theme.layout.contentWidth,
  width: '100%',
  margin: '0 auto',
  padding: theme.spacing(0, 2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 8),
  },
}));

const LogoContainer = styled('div')(({ theme }) => ({
  width: 100,
  height: 28,
  [theme.breakpoints.up('md')]: {
    width: 120,
    height: 32,
  },
}));

const LogoImage = styled(Image)({
  width: '100%',
  height: '100%',
});

const Topbar = ({ themeMode, className, ...rest }) => {
  return (
    <StyledToolbar className={className} {...rest}>
      <LogoContainer>
        <a href="/" title="thefront">
          <LogoImage
            src={themeMode === 'light' ? '/assets/logos/logo.png' : '/assets/logos/logo.png'}
            alt="MindsClik"
            lazy={false}
          />
        </a>
      </LogoContainer>
    </StyledToolbar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  themeMode: PropTypes.string.isRequired,
};

export default Topbar;
