import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {  Grid } from '@mui/material';
import { SectionHeader } from 'components/molecules';
import { Image } from 'components/atoms';

const useStyles = makeStyles((theme) => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase',
  },
}));

const WeStandFor = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <Grid container justify="center">
        <SectionHeader
          title={<span className={classes.textWhite}>We Stand For</span>}
          titleVariant="h2"
          data-aos="fade-up"
        />
        <Grid item xs={12} sm={6}>
          <Image
            src={'assets/images/home/we_stand_for.png'}
            alt={'We Stand For'}
          />
        </Grid>
        <Grid item xs={12} sm={1} />
        <Grid item xs={12} sm={5}>
          <SectionHeader
            title={<span className={classes.textWhite}></span>}
            subtitle={
              <div>
              <br/>
              <br/>
              <span className={classes.textWhite}>
                Enabling Business Transformation
              </span>
              <br/>
              <br/>
              <span className={classes.textWhite}>
                by Digital Enablement to
              </span>
              <br/>
              <br/>
              <span className={classes.textWhite}>
                Elevate User Experience
              </span>
              </div>
            }
            align="left"
            disableGutter
          />
        </Grid>
      </Grid>


    </div>
  );
};

WeStandFor.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WeStandFor;
