import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import clsx from 'clsx';

// Styled component
const StyledSection = styled('section')(({ theme, fullWidth, narrow, disablePadding }) => ({
  maxWidth: fullWidth ? '100%' : theme.layout.contentWidth,
  width: '100%',
  margin: '0 auto',
  padding: disablePadding ? 0 : theme.spacing(6, 2),
  ...(fullWidth ? {} : {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 8),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 8),
    },
  }),
  ...(narrow && { maxWidth: 800 }),
}));

/**
 * Component to display the sections
 *
 * @param {Object} props
 */
const Section = props => {
  const {
    children,
    fullWidth,
    narrow,
    disablePadding,
    className,
    ...rest
  } = props;

  return (
    <StyledSection
      fullWidth={fullWidth}
      narrow={narrow}
      disablePadding={disablePadding}
      className={clsx('section', className)}
      {...rest}
    >
      {children}
    </StyledSection>
  );
};

Section.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Children to placed inside the section
   */
  children: PropTypes.node,
  /**
   * Should show narrow sections
   */
  narrow: PropTypes.bool,
  /**
   * Should the section be full width
   */
  fullWidth: PropTypes.bool,
  /**
   * Should the section render with no padding
   */
  disablePadding: PropTypes.bool,
};

export default Section;
