import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, IconButton, Box } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

/**
 * Component to display the "Learn More" link
 *
 * @param {Object} props
 */
const LearnMoreLink = (props) => {
  const {
    color,
    component,
    variant,
    title,
    href,
    className,
    iconProps,
    typographyProps,
    ...rest
  } = props;

  const children = (
    <>
      <Typography
        component="span"
        variant={variant}
        color={color || 'primary'}
        sx={{ fontWeight: 'bold' }}
        {...typographyProps}
      >
        {title}
      </Typography>
      <IconButton
        color={color || 'primary'}
        sx={{ padding: 0, marginLeft: 1, '&:hover': { background: 'transparent' } }}
        {...iconProps}
      >
        <ArrowRightAltIcon />
      </IconButton>
    </>
  );

  return (
    <Box
      component="a"
      href={href}
      className={clsx('learn-more-link', className)}
      sx={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}
      {...rest}
    >
      {children}
    </Box>
  );
};

LearnMoreLink.defaultProps = {
  variant: 'subtitle1',
  href: '#',
  typographyProps: {},
  iconProps: {},
  component: 'a',
};

LearnMoreLink.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The component to load as a main DOM
   */
  component: PropTypes.oneOf(['Link', 'a']),
  /**
   * Title of the link
   */
  title: PropTypes.string.isRequired,
  /**
   * Variant of the link
   */
  variant: PropTypes.oneOf(['h6', 'subtitle1', 'subtitle2', 'body1', 'body2']),
  /**
   * Href of the link
   */
  href: PropTypes.string,
  /**
   * Color of the link
   */
  color: PropTypes.string,
  /**
   * Additional properties to pass to the Icon component
   */
  iconProps: PropTypes.object,
  /**
   * Additional properties to pass to the Typography component
   */
  typographyProps: PropTypes.object,
};

export default LearnMoreLink;
